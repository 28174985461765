import React from "react";
import { GoogleLogin } from "react-google-login";
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.onSignIn = this.onSignIn.bind(this);
  }

  onSignIn(googleUser) {
    let profile = googleUser.getBasicProfile();
    this.props.setUserProfile({ name: profile.getName(), avatar: profile.getImageUrl(), authToken: googleUser.getAuthResponse().id_token });
    this.props.setIsLoggedIn(true);
  }

  render() {
    return (
      <div className="loginPageBody">
        <div className="container">
          <div className="row">
            <div className="col"></div>
            <div className="col col-lg-6 loginBox">
              <div className="row">
                <div className="col">
                  <img className="mx-auto cluepLogo" src="Logo.svg" alt="Cluep logo" />
                </div>
              </div>
              {/* <div className="row form-input">
                <div className="col-10 mx-auto">
                  <label htmlFor="loginInput" className="form-label ">
                    Password
                  </label>
                  <input  placeholder="Password" aria-label="Password" type="password" className="form-control" id="loginInput" value={this.state.password} onChange={this.handleChange} />
                </div>
              </div> */}

              <div className="row">
                <div className="col-7 mx-auto">
                  {/* <button type="button" className="btn col-lg-10 btn-primary submit-button" onClick={this.checkPassword}>
                    Submit
                  </button> */}
                  <GoogleLogin
                    clientId="304301191717-vb2jjuq8qvd4jnkspbjt2el0i7jmc45i.apps.googleusercontent.com"
                    buttonText="Log in with Cluep Gmail"
                    onSuccess={this.onSignIn}
                    // onFailure={handleLogin}
                    cookiePolicy={"single_host_origin"}
                  />
                </div>
              </div>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
