import ReactHtmlParser from "react-html-parser";

function Dialogue(props) {
  if (props.action === "typing") {
    return (
      <div className={`${props.sender}-typing-indicator`}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  } else {
    return (
      <div>
        {props.sender === "bot" && props.action !== "select" && (
          <div className="dialogue-bot">
            <span className="c_avatar">
              <img src="/Cluep_Avatar.png" alt="cluep avatar" />
            </span>
            {props.action === "keyword" ? <div>{ReactHtmlParser(props.text)}</div> : <div>{props.text}</div>}
          </div>
        )}

        {props.sender === "bot" && props.action === "select" && (
          <div className="dialogue-bot">
            <span className="c_avatar">
              <img src="/Cluep_Avatar.png" alt="cluep avatar" />
            </span>
            <div>
              {props.text}
              <span className="clickable" onClick={props.method}>
                English
              </span>
              <span className="clickable" onClick={props.method}>
                French
              </span>
              <span className="clickable" onClick={props.method}>
                German
              </span>
              <span className="clickable" onClick={props.method}>
                Spanish
              </span>
            </div>
          </div>
        )}

        {props.sender === "user" && (
          <div className=" row dialogue-user">
            <div className="col-10">{props.text}</div>
            <span className=" col-1 avatar">
              <img src={props.avatar} alt="user avatar" />
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default Dialogue;
