const axios = require("axios").default;

const baseUrl = "https://audio.cluep.com/api";

// const baseUrl = "http://localhost:3001/api";

export const analyze = async (keywords, text, authToken) => {
  return axios({
    method: "post",
    url: `${baseUrl}/analyze`,
    data: {
      keywords: keywords.split(","),
      text: text,
      authToken: authToken,
    },
    headers: { "Content-Type": "application/json" },
  });
};

export const processAudioData = async (bodyFormData, language) => {
  return axios({
    method: "post",
    url: `${baseUrl}/audio?language=${language}`,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};
