import { useState, useEffect } from "react";
import Chat from "./components/chat";
import Login from "./components/login";

function App() {
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [slideNumber, setSlideNumber] = useState(4);
  const [userProfile, setUserProfile] = useState({ name: "there", avatar: "/Avatar.png", authToken: "" });

  useEffect(() => {
    checkPermission();
  }, []);

  const checkPermission = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      console.log("getUserMedia supported.");
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const options = {
          audioBitsPerSecond: 48000,
          mimeType: "audio/webm; codecs=opus",
        };
        const mediaRecorder = new MediaRecorder(mediaStream, options);
        setAudioRecorder(mediaRecorder);
      } catch (err) {
        console.log("The following getUserMedia error occurred: " + err);
      }
    } else {
      console.log("getUserMedia not supported on your browser!");
    }
  };

  return (
    <div>
      <div className={isLoggedIn ? "d-none" : ""}>
        <Login setIsLoggedIn={setIsLoggedIn} setUserProfile={setUserProfile} />
      </div>
      <div className={isLoggedIn ? "" : "d-none"}>
        <div className="outerBody">
          {/* <div className="logo">
            <img src="/Logo.svg" alt="cluep logo" />
          </div> */}
          {/* <div className="slideshow">
            {[1, 2, 3, 4, 5, 6, 7].map((num) => {
              return (
                <span
                  key={num}
                  className={slideNumber === num ? "selectedslide" : ""}
                  onClick={() => {
                    setSlideNumber(num);
                  }}
                ></span>
              );
            })}
          </div> */}
          {/* {slideNumber === 1 && (
            <div className="slide-1 mx-auto">
              <div className="row">
                <div className="col-3">
                  <img className="slide-1-audio-mic mx-auto" src="audio-icon.png" alt="audio"></img>
                </div>

                <div className="col-8 slide-1-right-side">
                  <div className="slide-1-title">
                    Cluep <span>Audio</span>
                  </div>
                  <div className="slide-1-content">
                    Target people based on what they <br></br> publicly say and listen to.
                  </div>
                </div>
              </div>
            </div>
          )}
          {slideNumber === 2 && (
            <div className="slide-2">
              <div className="row">
                <div className="col col-lg-12">
                  <div className="slide-2-title">Social audio is the frontier of ambient computing</div>
                  <div className="slide-2-subtitle">
                    COVID-19 greatly accelerated the global adoption of social audio as an authentic and serendipitous way to build human connection online, and it's here to
                    stay
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col col-lg-12 slide-2-content">
                  <span className="slide-2-chart-y-label text-left">
                    Authenticity of online <br></br> human connection
                  </span>
                  <img className="slide-2-chart" src="/Chart.png" alt="chart"></img>
                  <span className="slide-2-chart-x-label mx-auto">Time</span>
                </div>
              </div>
            </div>
          )}
          {slideNumber === 3 && (
            <div className="slide-3">
              <div className="row">
                <div className="slide-3-box-main-title">How it works</div>
              </div>
              <div className="row slide-3-content">
                <div className="col-4">
                  <div className="slide-3-box ms-1">
                    <div className="slide-3-box-title">A person publicly posts or engages with audio content.</div>
                    <div className="row">
                      <ul className="slide-3-box-platforms">
                        <li>
                          <img src="/twitter.png" alt="twitter"></img>
                          <span>
                            Twitter<br></br>Spaces
                          </span>
                        </li>
                        <li>
                          <img src="/twitter.png" alt="twitter"></img>
                          <span>
                            Twitter<br></br>Tweets
                          </span>
                        </li>
                        <li>
                          <img src="/youtube.png" alt="youtube"></img>
                          <span>Youtube</span>
                        </li>
                        <li>
                          <img src="/Tumblr.png" alt="tumblr"></img>
                          <span>Tumblr</span>
                        </li>
                      </ul>
                      <img className="slide-3-box-1-image" src="/Iphone.png" alt="iphone"></img>
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="slide-3-box ms-1">
                    <div className="slide-3-box-title">Determine if the desired keywords are present using Cluep's proprietary automatic speech recognition (ASR) engine.</div>
                    <div className="row">
                      <img className="slide-3-box-2-image-video mx-auto" src="/Video.png" alt="video"></img>
                    </div>
                    <div className="row">
                      <img className="slide-3-box-2-image-voice  mx-auto" src="/Voice.png" alt="voice"></img>
                    </div>
                    <div className="slide-3-box-footer text-center">
                      Day two of eating oatmeal <br></br> instead of <span>sugar cereal</span> for <br></br> breakfast being a healthy <br></br> adult is hard LOL
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="slide-3-box ms-1">
                    <div className="slide-3-box-title">Serve a relevant ad to this person in their mobile apps and mobile websites.</div>
                    <div className="row">
                      <img className="slide-3-box-3-image mx-auto" src="/Mobile-Ads.png" alt="mobile ad"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}
          {
            /* {slideNumber === 4 && ( */
            isLoggedIn && (
              <div className="slide-4">
                {audioRecorder ? (
                  <div className="chatApp container">
                    <div className="row">
                      <div className="col-12">
                        <img className="cluep-app-logo mx-auto" src="/Logo.svg" alt="logo" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">{<Chat audioRecorder={audioRecorder} userProfile={userProfile} />}</div>
                    </div>
                  </div>
                ) : (
                  <div>This demo requires you to give access to microphone! Thanks!</div>
                )}
              </div>
            )
            /* )} */
          }
          {/* {slideNumber === 5 && (
            <div className="slide-5">
              <div className="row slide-5-box-title">Deep Speech Architecture</div>

              <div className="row slide-5-box-content">
                Cluep's Speech-to-text engine uses a deep recurrent neural network to ingest speech spectrograms transcoded from .WAV files and generate natural lanquage text
                transcriptions. We use a full end-to-end deep learning pipeline to simplify processing and allows the direct learning of background noise, reverberation and
                speaker variation.
              </div>

              <div className="container">
                <div className="row justify-content-md-center">
                  <div className="col col-lg-3 slide-5-box-stats">
                    <img className="mx-auto slide-5-box-microphone" src="Microphone.png" alt="microphone"></img>
                    <span className="text-center slide-5-box-context">Total Speaking hours</span>
                    <span className="text-center slide-5-box-number">2,637</span>
                  </div>
                  <div className="col col-lg-3 slide-5-box-stats">
                    <img className="slide-5-box-playbutton mx-auto " src="Chat Icon.png" alt="chatting"></img>
                    <span className="text-center slide-5-box-context">Number of voices</span>
                    <span className="text-center slide-5-box-number">75,879</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {slideNumber === 6 && (
            <div className="slide-6">
              <div className="container">
                <div className="row">
                  <div className="slide-6-title">Diverse and rich dataset</div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="slide-6-box">
                      <div className="slide-6-box-title">Gender Split</div>
                      <img className="slide-6-gender-split mx-auto" src="/Gender split.png" alt="gender split"></img>
                      <div className="legend gender-legend">
                        <div className="">
                          <span className="legend-color male-legend"></span>
                          <span className="legend-text">Male</span>
                        </div>
                        <div className="">
                          <span className="legend-color female-legend"></span>
                          <span className="legend-text">Female</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="slide-6-box">
                      <div className="slide-6-box-title">Age Split</div>
                      <img className="slide-6-age-split mx-auto" src="age_split.png" alt="age split"></img>
                      <div className="legend legend-age float-start">
                        <div className="">
                          <span className="legend-color legend-1929"></span>
                          <span className="legend-text">19-29</span>
                        </div>
                        <div className="">
                          <span className="legend-color legend-3039"></span>
                          <span className="legend-text">30-39</span>
                        </div>
                        <div className="">
                          <span className="legend-color legend-4049"></span>
                          <span className="legend-text">40-49</span>
                        </div>
                        <div className="">
                          <span className="legend-color legend-under19"></span>
                          <span className="legend-text"> Under 19</span>
                        </div>
                      </div>
                      <div className="legend  legend-age float-end">
                        <div className="">
                          <span className="legend-color legend-5059"></span>
                          <span className="legend-text">50-59</span>
                        </div>
                        <div className="">
                          <span className="legend-color legend-6069"></span>
                          <span className="legend-text">60-69</span>
                        </div>
                        <div className="">
                          <span className="legend-color legend-7079"></span>
                          <span className="legend-text">70-79</span>
                        </div>
                        <div className="">
                          <span className="legend-color legend-noage"></span>
                          <span className="legend-text">Unspecified</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="slide-6-box">
                      <div className="slide-6-box-title">Accent Split</div>
                      <img className="slide-6-accent-split mx-auto" src="Accent_split.png" alt="accent split"></img>
                      <div className="legend legend-accent float-start">
                        <div className="">
                          <span className="legend-color legend-us"></span>
                          <span className="legend-text">United States English</span>
                        </div>
                        <div className="">
                          <span className="legend-color legend-uk"></span>
                          <span className="legend-text">England English</span>
                        </div>
                        <div className="">
                          <span className="legend-color legend-in"></span>
                          <span className="legend-text">India and South Asia</span>
                        </div>
                        <div className="">
                          <span className="legend-color legend-au"></span>
                          <span className="legend-text">Australian English</span>
                        </div>
                        <div className="">
                          <span className="legend-color legend-ca"></span>
                          <span className="legend-text">Canadian English</span>
                        </div>
                      </div>{" "}
                      <div className="legend legend-accent float-end">
                        <div className="">
                          <span className="legend-color legend-sc"></span>
                          <span className="legend-text">Scottish English</span>
                        </div>
                        <div className="">
                          <span className="legend-color legend-ir"></span>
                          <span className="legend-text">Irish English</span>
                        </div>
                        <div className="">
                          <span className="legend-color legend-sa"></span>
                          <span className="legend-text">South African</span>
                        </div>
                        <div className="">
                          <span className="legend-color legend-nz"></span>
                          <span className="legend-text">New Zealand English</span>
                        </div>
                        <div className="">
                          <span className="legend-color legend-un"></span>
                          <span className="legend-text">Unspecified</span>
                        </div>
                      </div>
                      <div className="legend"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {slideNumber === 7 && (
            <div className="slide-7">
              <div className="container">
                <div className="row">
                  <div className="col col-lg-12 slide-7-header-title">Advertising that's private by design</div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="slide-7-box">
                      <img className="slide-7-ghost mx-auto" src="Ghost.png" alt="ghost"></img>
                      <span className="slide-7-box-content text-center">
                        We don't know <br></br>who you are
                      </span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="slide-7-box">
                      <img className="slide-7-tomato mx-auto" src="Tomato.png" alt="tomato"></img>
                      <span className=" slide-7-box-content text-center">
                        We don't know <br></br>your name
                      </span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="slide-7-box">
                      <img className="slide-7-gmail mx-auto" src="Gmail.png" alt="gmail"></img>
                      <span className=" slide-7-box-content text-center">
                        We don't know <br></br>our email address
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row slide-7-footer">
                <div className="col slide-7-footer-title">tl;dr Your personal data is none of our business</div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default App;
