import React from "react";
import Dialogue from "./dialogue";
import * as apiService from "../api-service";

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialTexts: [`Hi ${this.props.userProfile.name}!`, "I'm going to walk you through a live demo of Cluep Audio"],
      textAfterLang: ["To get started, can you message me 3 words that come to your mind?", "You can say anything like pasta 🍝 or pizza 🍕"],
      textAfterKeywords: "",
      textWhenFinishedSuccess: "This is how we analyze speech inside public content to understand what it's about.",
      dialogueTexts: [],
      keywords: "",
      recordedText: "",
      audioRecorder: this.props.audioRecorder,
      showInputBox: true,
      isRecorderShown: true,
      isKeywordsEntered: false,
      isRecordingProcessing: false,
      isRecordingFinished: false,
      isSubmitted: false,
      isRecorderOn: false,
      textAreaText: "",
      languageSelected: "",
      languageCode: "en-US",
    };

    this.loadBotTexts = this.loadBotTexts.bind(this);
    this.addBotDialogue = this.addBotDialogue.bind(this);
    this.startRecording = this.startRecording.bind(this);
    this.stopRecording = this.stopRecording.bind(this);
    this.submitRecording = this.submitRecording.bind(this);
    this.toggleRecorder = this.toggleRecorder.bind(this);
    this.addKeywords = this.addKeywords.bind(this);
    this.addRecordedText = this.addRecordedText.bind(this);
    this.redoRecording = this.redoRecording.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.startTypingIndicator = this.startTypingIndicator.bind(this);
    this.toggleRecording = this.toggleRecording.bind(this);
    this.addTextAreaText = this.addTextAreaText.bind(this);
    this.selectLanguage = this.selectLanguage.bind(this);
    this.getLanguageCode = this.getLanguageCode.bind(this);
  }

  async componentDidMount() {
    await this.loadBotTexts();
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    this.messagesEnd.scrollIntoView({ behavior: "smooth", block: "nearest" });
  }

  startTypingIndicator(sender) {
    let nextDialogue = {
      sender: sender,
      text: "",
      action: "typing",
    };

    this.setState({ dialogueTexts: [...this.state.dialogueTexts, nextDialogue] });
  }

  async addBotDialogue(text, action, method) {
    await new Promise((r) => setTimeout(r, 2000));
    let dialogueTexts = [...this.state.dialogueTexts];
    dialogueTexts.pop();
    let nextDialogue = {
      sender: "bot",
      text: text,
    };
    if (action === "select") {
      nextDialogue = { ...nextDialogue, action, method };
    }
    this.setState({ dialogueTexts: [...dialogueTexts, nextDialogue] });
  }

  async loadBotTexts() {
    for (const text of this.state.initialTexts) {
      this.startTypingIndicator("bot");
      await this.addBotDialogue(text);
      this.scrollToBottom();
    }
    // let nextDialogue = {
    //   sender: "bot",
    //   action: "select",
    //   text: "First choose a language from these options - ",
    //   method: this.selectLanguage,
    // };
    // this.setState({ dialogueTexts: [...this.state.dialogueTexts, nextDialogue] });
    this.startTypingIndicator("bot");
    await this.addBotDialogue("First choose a language from these options - ", "select", this.selectLanguage);
  }

  toggleRecording() {
    if (this.state.isRecorderOn === false) {
      this.setState({ isRecorderOn: true });
      this.startRecording();
    } else {
      this.setState({ isRecorderOn: false });
      this.stopRecording();
    }
  }

  addTextAreaText() {
    if (this.state.isKeywordsEntered === false) {
      this.addKeywords();
      this.setState({ textAreaText: "" });
    } else {
      this.addRecordedText();
      this.setState({ textAreaText: "" });
    }
  }

  startRecording() {
    let chunks = [];

    const setStateForTranscription = (res) => {
      this.setState({ recordedText: res.data.transcription, isRecordingProcessing: false, isRecordingFinished: true, textAreaText: res.data.transcription });
    };

    let recorder = this.state.audioRecorder;

    recorder.ondataavailable = function (e) {
      chunks.push(e.data);
    };

    recorder.onstop = function (e) {
      const blob = new Blob(chunks, { type: "audio/webm; codecs=opus" });
      const bodyFormData = new FormData();
      bodyFormData.append("audio", blob);
      bodyFormData.append("authToken", this.props.userProfile.authToken);
      apiService
        .processAudioData(bodyFormData, this.state.languageCode)
        .then(setStateForTranscription)
        .catch(() => {
          let nextDialogue = [
            {
              sender: "bot",
              text: "Something went wrong, please try again!",
            },
          ];

          this.setState({
            dialogueTexts: [...this.state.dialogueTexts, ...nextDialogue],
            isRecordingProcessing: false,
            isRecordingFinished: true,
            isSubmitted: true,
          });
        });
    }.bind(this);
    this.setState({ audioRecorder: recorder });

    this.toggleRecorder();
    this.state.audioRecorder.start();
  }

  stopRecording() {
    this.setState({ isRecordingProcessing: true });
    this.state.audioRecorder.stop();
  }

  redoRecording() {
    this.setState({
      isRecorderShown: true,
      isRecordingProcessing: false,
      isRecordingFinished: false,
      textAreaText: "",
    });
  }

  submitRecording() {
    const searchForKeyword = async () => {
      let responseText = "";
      let response;
      let success = false;
      const lastDialogue = this.state.dialogueTexts[this.state.dialogueTexts.length - 1]["text"];
      try {
        response = await apiService.analyze(this.state.keywords, lastDialogue, this.props.userProfile.authToken);
        responseText = response.data.data;
        if (response.data.status === "success") {
          success = true;
        }
      } catch (e) {
        responseText = "Something went wrong, please try again!";
        console.log("Error processing!");
        return;
      }

      let nextDialogue = [
        {
          sender: "bot",
          text: responseText,
          action: "keyword",
        },
      ];

      if (success) {
        nextDialogue = [
          ...nextDialogue,
          {
            sender: "bot",
            text: this.state.textWhenFinishedSuccess,
          },
        ];
      }

      this.setState({
        dialogueTexts: [...this.state.dialogueTexts, ...nextDialogue],
      });
    };

    searchForKeyword();
  }

  toggleRecorder() {
    this.setState({ isRecorderShown: !this.state.isRecorderShown });
  }

  addKeywords() {
    let nextDialogue = {
      sender: "bot",
      text: `${this.state.textAfterKeywords} ${this.state.keywords}.`,
    };
    let userKeywordsDialogue = {
      sender: "user",
      text: this.state.keywords,
      avatar: this.props.userProfile.avatar,
    };
    this.setState({ dialogueTexts: [...this.state.dialogueTexts, userKeywordsDialogue, nextDialogue], showInputBox: false });
    this.setState({ isKeywordsEntered: true });
  }

  addRecordedText(recordedText) {
    let recordedDialogue = {
      sender: "user",
      text: this.state.recordedText,
      avatar: this.props.userProfile.avatar,
    };
    let newState = [...this.state.dialogueTexts, recordedDialogue];
    this.setState({ dialogueTexts: [...newState], isSubmitted: true }, this.submitRecording);
  }

  async selectLanguage(e) {
    if (this.state.languageSelected) {
      return;
    }
    let selectedLanguage = e.target.innerText;
    this.setState({
      languageSelected: selectedLanguage,
      textAfterKeywords: `Awesome. Now I need to you speak something in ${selectedLanguage}, anything really. But it has to include`,
      languageCode: this.getLanguageCode(selectedLanguage.toLowerCase()),
    });
    this.startTypingIndicator("bot");
    await this.addBotDialogue(`Language set to ${selectedLanguage}`);
    for (const text of this.state.textAfterLang) {
      this.startTypingIndicator("bot");
      await this.addBotDialogue(text);
      this.scrollToBottom();
    }
  }

  getLanguageCode(lang) {
    return {
      english: "en-US",
      french: "fr-FR",
      german: "de-DE",
      spanish: "es-ES",
    }[lang];
  }

  render() {
    return (
      <div className="chatApp-render">
        <div className="dialogBubble">
          <div className="empty-bubble"></div>
          {this.state.dialogueTexts.map((dialogue, key) => {
            return <Dialogue sender={dialogue.sender} text={dialogue.text} action={dialogue.action} avatar={dialogue.avatar} method={dialogue.method} key={key} />;
          })}
          <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              this.messagesEnd = el;
            }}
          ></div>
          <div className="empty-bubble"></div>
        </div>

        <div className="row user-input-container">
          <div className="col-12">
            <div className={this.state.isRecordingFinished && !this.state.isSubmitted ? "row input-custom processed-input" : "row input-custom"}>
              <div className="input-box">
                <textarea
                  onChange={(event) => {
                    if (this.state.isKeywordsEntered === false) {
                      this.setState({ keywords: event.target.value, textAreaText: event.target.value });
                    }
                  }}
                  value={this.state.textAreaText}
                ></textarea>
              </div>
              <div className="input-button-box">
                {!this.state.isKeywordsEntered &&
                  (this.state.keywords.length > 0 ? (
                    <span className="button_icon" onClick={this.addTextAreaText}>
                      <img src="/Send_icon.png" alt="send button" />
                    </span>
                  ) : (
                    <span className="button_icon">
                      <img src="/Deactive_Record_Icon.png" alt="deactive record button" />
                    </span>
                  ))}
                {this.state.isKeywordsEntered && !this.state.isRecordingFinished && this.state.isRecorderShown && (
                  <span className="button_icon" onClick={this.toggleRecording}>
                    <img src="/Record_Icon.png" alt="record button" />
                  </span>
                )}
                {this.state.isKeywordsEntered && !this.state.isRecordingFinished && !this.state.isRecorderShown && !this.state.isRecordingProcessing && (
                  <span className="button_icon stop-recording" onClick={this.toggleRecording}>
                    □
                  </span>
                )}
                {this.state.isRecordingProcessing && <div className="loader"></div>}
                {!this.state.isRecordingProcessing && this.state.isRecordingFinished && !this.state.isSubmitted && (
                  <div>
                    <span className="button_icon submit-icon" onClick={this.addTextAreaText}>
                      <img src="/Send_icon.png" alt="send button" />
                    </span>
                    <span className="button_icon redo-icon" onClick={this.redoRecording}>
                      <img src="/Redo_Icon.png" alt="redo button" />
                    </span>
                  </div>
                )}
                {this.state.isSubmitted && (
                  <span className="button_icon">
                    <img src="/Deactive_Record_Icon.png" alt="deactive record button" />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Chat;
